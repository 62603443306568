<template>
	<div class="van-nav-tb">
		<van-nav-bar
			title="标题"
			left-text=""
			left-arrow
			@click-left="$router.go(-1)"
		/>
	</div>
	
</template>

<script>
	import { Toast } from 'vant';
	
	export default {
		methods: {
			onClickLeft() {
				Toast('返回');
			},
		},
	};
</script>

<style scoped>
	ul {
		list-style-type: none;
		padding: 0;
	}
	li {
		display: inline-block;
		margin: 0 10px;
	}
	/deep/.van-nav-bar__arrow{
		color: #000000 !important;
		font-size: 20px;
	}
	/* .van-nav-bar {
		.van-nav-bar__arrow {
			color: #000000;
			font-size: 20px;
		}
	} */
</style>