<template>
	<div class="">
		<div class="van-nav-tb">
			<van-nav-bar
				title="学时查询"
				left-text=""
				left-arrow
				@click-left="$router.go(-1)"
			/>
		</div>
		<div class="details">
			<div class="details1">
				<div class="details2">
					<i></i>
					<h1>培训班名称</h1>
				</div>
				<span>全市国有企业党组织书记培训示范班</span>
			</div>
			<div class="details1">
				<div class="details2">
					<i></i>
					<h1>课程名称</h1>
				</div>
				<span>全市国有企业党组织书记培训示范班全市国有企业党组织书记培训示范班</span>
			</div>
			<div class="details1">
				<div class="details2">
					<i></i>
					<h1>培训地点</h1>
				</div>
				<span>呼和浩特市</span>
			</div>
			<div class="details1">
				<div class="details2">
					<i></i>
					<h1>主办单位</h1>
				</div>
				<span>市委组织部</span>
			</div>
			<div class="details3">
				<div class="details3_1">
					<div class="details3_2">
						<i></i>
						<h1>开始时间</h1>
					</div>
					<span>2023/10/16</span>
				</div>
				<div class="details3_1">
					<div class="details3_2">
						<i></i>
						<h1>结束时间</h1>
					</div>
					<span>2023/10/20</span>
				</div>
			</div>
			<div class="details1">
				<div class="details2">
					<i></i>
					<h1>是否通过</h1>
				</div>
				<span>是</span>
			</div>
			<div class="details1">
				<div class="details2">
					<i></i>
					<h1>总学时</h1>
				</div>
				<span>20</span>
			</div>
		</div>
		<div style="height: 20px;"></div>
		<GbFooter></GbFooter>
	</div>
</template>

<script>
	export default {
		name: 'XscxListycIndex',
		data() {
			return {
				
			};
		},
	};
</script>

<style>
	.details{
		width: 90%;
		min-height: 498px;
		background: #FFFFFF;
		box-shadow: 0px 1px 18px 0px rgba(0,0,0,0.1);
		border-radius: 10px;
		margin: 20px auto;
	}
	.details1{
		width: 90%;
		min-height: 75px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-bottom: solid 1px #E5E5E5;
		margin: 0 auto;
	}
	.details2 {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 12px;
	}
	.details2 i{
		width: 5px;
		height: 14px;
		background: #F52C2E;
		border-radius: 5px;
	}
	.details2 h1{
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #F52C2E;
		margin-left: 10px;
		margin-bottom: 3px;
		margin-top: 3px;
	}
	.details1 span{
		width: 100%;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		margin-bottom: 10px;
	}
	.details3{
		width: 90%;
		display: flex;
		flex-direction: row;
		margin: 0 auto;
	}
	.details3_1{
		width: 50%;
		min-height: 75px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-bottom: solid 1px #E5E5E5;
		margin: 0 auto;
	}
	.details3_2 {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 12px;
	}
	.details3_2 i{
		width: 5px;
		height: 14px;
		background: #F52C2E;
		border-radius: 5px;
	}
	.details3_2 h1{
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #F52C2E;
		margin-left: 10px;
		margin-bottom: 3px;
		margin-top: 3px;
	}
	.details3_1 span{
		width: 100%;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
	}
</style>