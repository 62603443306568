import store from '@/store'
import axios from 'axios'
import Vuex from 'vuex'
import router from '@/router/index'
import { Toast } from 'vant'

import { getToken, setToken } from "@/utils/token";
// import { Toast } from 'vant'
import qs from 'qs'

// 创建 axios 实例，将来对创建出来的实例，进行自定义配置
// 好处：不会污染原始的 axios 实例
const instance = axios.create({
	// baseURL: 'http://118.31.5.142:10110/',
  baseURL: 'https://yqgjk.ewdjy.cn/prod-api',
  headers: {
	'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  timeout: 5000
})

// 自定义配置 - 请求/响应 拦截器
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
	// console.log(config,999999)
	// if(config.headers['Content-Type'] != 'multipart/form-data'){
	// 	config.data = qs.stringify(config.data)
	// }
	
	// 在发送请求之前做些什么
	// 只要有token，就在请求时携带，便于请求需要授权的接口
	// const token = localStorage.getItem('token')
	const token =getToken()
	if (token) {
		config.headers['Authorization'] = token
		// config.headers.platform = 'H5'
	}
	
    // 在发送请求之前做些什么
    return config
  });

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    if (response.status === 200) {
			return response.data;
		} else {
			return Promise.reject(response.message)
		}
	}, function (error) {
	if (error.response && error.response.status && error.response.data.code === 401) {
		// this.$toast('未登录或登录超时');
		Toast(error.response.data.msg);
		router.push('/login');
	}
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  });

// 导出配置好的实例
export default instance