<template>
	<div class="">
		<div class="van-nav-tb">
			<van-nav-bar
				title="学时申请编辑"
				left-text=""
				left-arrow
				@click-left="$router.go(-1)"
			/>
		</div>
		<div class="editor">
			<div class="editor1">
				<div class="editor2">
					<i></i>
					<h1>学时类型</h1>
				</div>
				<div class="Select">
					<van-dropdown-menu>
						<van-dropdown-item v-model="periodType" :options="option1" />
						<img src="@/assets/you1.png" alt="" />
					</van-dropdown-menu>
				</div>
			</div>
			<div class="editor1">
				<div class="editor2">
					<i></i>
					<h1>课程名称</h1>
				</div>
				<div class="shuru">
					<input type="text" v-model="courseName" placeholder="请输入课程名称" name="courseName"/>
				</div>
			</div>
			<div class="editor3">
				<div class="editor3_1">
					<div class="editor3_2">
						<i></i>
						<h1>开始时间</h1>
					</div>
					<div class="kssj">
						<Row>
							<Col span="12">
								<DatePicker type="date" @input="convertDateFormat" v-model="startTime" placeholder="请选择开始时间" format="yyyy/MM/dd" style="width: 140px"></DatePicker>
							</Col>
						</Row>
					</div>
				</div>
				<div class="editor3_1">
					<div class="editor3_2">
						<i></i>
						<h1>结束时间</h1>
					</div>
					<div class="jssj" id="jssj">
						<Row>
							<Col span="12">
								<DatePicker type="date" @input="convertDateFormatend" v-model="endTime" placeholder="请选择结束时间" format="yyyy/MM/dd" style="width: 140px"></DatePicker>
							</Col>
						</Row>
					</div>
				</div>
			</div>
			<div class="editor1">
				<div class="editor2">
					<i></i>
					<h1>学时</h1>
				</div>
				<div class="shuru">
					<input type="text" v-model="courseHour" placeholder="请输入学时" />
				</div>
			</div>
			<div class="editor1">
				<div class="editor2">
					<i></i>
					<h1>培训地点</h1>
				</div>
				<div class="shuru">
					<input type="text" v-model="trainingLocation" placeholder="请输入培训地点" />
				</div>
			</div>
			<div class="editor1">
				<div class="editor2">
					<i></i>
					<h1>主办单位</h1>
				</div>
				<div class="shuru">
					<input type="text" v-model="trainingOrgan" placeholder="请输入主办单位" />
				</div>
			</div>
			<div class="editor1_sc">
				<div class="editor2">
					<i></i>
					<h1>上传附件</h1>
				</div>
				<div class="fujian">
					
					<van-uploader @oversize="onOversize" :before-read="beforeRead" :after-read="clzpAfterRead" v-model="fileList"
					      :max-count="1" :max-size="5 * 1024 * 1024" />
					 <!-- <img :src="img" alt="" v-else> -->
				</div>
			</div>
			<div class="editor1">
				<div class="editor2">
					<i></i>
					<h1>备注</h1>
				</div>
				<div class="dhshuru">
					<textarea name="" id="" cols="40" rows="3" v-model="remarks" placeholder="请输入备注内容" ></textarea>
					<!-- <input type="text" v-model="value" placeholder="请输入主办单位" /> -->
				</div>
			</div>
		</div>
		<div class="sqan1">
			<button class="save" @click="postForm">保&nbsp;&nbsp;存</button>
			<button class="delete" @click="deleteForm">删&nbsp;&nbsp;除</button>
		</div>
		<div style="height: 50px;"></div>
		<GbFooter></GbFooter>
	</div>
</template>

<script>
	import { getEditorData } from '@/api/xssq'
	import { getUploadData } from '@/api/xssq'
	import request from '@/utils/request'
	export default {
		name: 'EditorIndex',
		data() {
			return {
				periodType: '',
				courseName: '',
				startTime: '',
				convertedDate: '',
				endTime: '',
				convertedDateend: '',
				courseHour: '',
				trainingLocation: '',
				trainingOrgan: '',
				fileNmae:'',
				fileList: [
				], //上传的文件列表
				remarks: '',
				file: [],
				img:"",
				detailData: [],
				option1: [
					{ text: '请选择学时类型', value: 0 },
					{ text: '系统培训', value: 1 },
					{ text: '网络学时', value: 2 },
					{ text: '奖励学时', value: 3 },
					{ text: '自学学时', value: 4 },
				],
			};
		},
		created() {
			if(this.$route.query){
				this.id = this.$route.query.id;
				this.fetchDetailData(); // 在组件创建时发送请求获取详情数据
			}
		},
		methods: {
			//校验上传图片大小
			onOversize(file) {
				console.log(file);
				this.$toast("文件大小不能超过5MB");
			},
			//校验图片的格式
			beforeRead(file) {
				if (!/(jpg|jpeg|png|JPG|PNG)/i.test(file.type)) {
					this.$toast("请上传正确格式的图片");
					return false;
				}
				return true;
			},
			//照片上传事件方法
			async  clzpAfterRead(file) {
				// 上传状态提示开启
				// file.status = "uploading";
				// file.message = "上传中...";
				// 创建一个空对象实例
				var formData = new FormData();
				 
				// 调用append()方法添加数据
				formData.append("file", file.file);
				const res = await getUploadData({file: file.file})
				if (res.code == "200") {
					console.log(res.url,111)
					// 上传状态提示关闭
					file.status = "done";
					this.$toast("上传成功！");
					this.fileName = res.url
				}
			},
			convertDateFormat() {
			    const date = new Date(this.startTime);
			    this.convertedDate = date.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/-/g, '/');
			},
			convertDateFormatend() {
			    const date = new Date(this.endTime);
			    this.convertedDateend = date.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/-/g, '/');
			},
			async fetchDetailData() {
				try {
					// 发送GET请求到详情接口，使用id作为查询参数
					const response = await request.get(`/client/user/classhour/form?id=${this.id}`);
					console.log(response,1111)
					this.detailData = response.data; // 将获取的数据存储在detailData中
					this.periodType = Number(response.data.periodType)
					this.courseName = this.detailData.courseName
					this.startTime = this.detailData.startTime
					this.endTime = this.detailData.endTime
					this.courseHour = this.detailData.courseHour
					this.trainingLocation = this.detailData.trainingLocation
					this.trainingOrgan = this.detailData.trainingOrgan
					let url = `http://yqgjk.ewdjy.cn/prod-api${response.data.fileName}`
					this.fileList.push({url:url})
					this.remarks = this.detailData.remarks
				} catch (error) {
					console.error('Error fetching detail data:', error);
				}
			},
			validFn () {
				if (this.periodType == "") {
					this.$toast('学时类型不能为空')
					return false
				}
				if (this.courseName == "") {
					this.$toast('课程名称不能为空')
					return false
				}
				if (this.convertedDate == "") {
					this.$toast('开始时间不能为空')
					return false
				}
				if (this.convertedDateend == "") {
					this.$toast('结束时间不能为空')
					return false
				}
				if (this.courseHour == "") {
					this.$toast('学时不能为空')
					return false
				}
				if (this.trainingLocation == "") {
					this.$toast('培训地点不能为空')
					return false
				}
				if (this.trainingOrgan == "") {
					this.$toast('主办单位不能为空')
					return false
				}
				if (this.fileName == "") {
					this.$toast('请上传附件')
					return false
				}
				if (this.remarks == "") {
					this.$toast('备注不能为空')
					return false
				}
				return true
			},
			// 提交
			async postForm () {
				if (!this.validFn()){
					return
				}
				// 表单提交
				const res = await getEditorData(this.id, this.periodType, this.courseName, this.convertedDate, this.convertedDateend, this.courseHour, this.trainingOrgan, this.trainingLocation, this.fileName, this.remarks)
				if(res.code==200){
					// 申请成功，显示成功消息或跳转到其他页面
					this.$toast('保存成功');
					const url = this.$route.query.backUrl || '/';
					this.$router.replace(url);
				}else{
					// 其他消息，根据msg信息进行相应处理
					this.$toast(res.msg);
				}
			},
			async deleteForm () {
				// 发送GET请求到详情接口，使用id作为查询参数
				const response = await request.get(`/client/user/classhour/delete?id=${this.id}`);
				if(response.code==200){
					// 删除成功，显示成功消息或跳转到其他页面
					this.$toast('删除成功');
					const url = this.$route.query.backUrl || '/xssq/ysqxslist';
					this.$router.replace(url);
				}else{
					// 其他消息，根据msg信息进行相应处理
					this.$toast(response.msg);
				}
			},
			afterRead(file) {
				file.status = 'uploading';
				file.message = '上传中...';
				
				setTimeout(() => {
					file.status = 'failed';
					file.message = '上传失败';
				}, 1000);
			},
		},
	};
</script>

<style>
	.editor{
		width: 90%;
		min-height: 498px;
		background: #FFFFFF;
		box-shadow: 0px 1px 18px 0px rgba(0,0,0,0.1);
		border-radius: 10px;
		margin: 20px auto;
	}
	.editor1{
		width: 90%;
		min-height: 75px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-bottom: solid 1px #E5E5E5;
		margin: 0 auto;
	}
	.editor1_sc{
		width: 90%;
		min-height: 75px;
		display: flex;
		flex-direction: column;
		/* align-items: center; */
		border-bottom: solid 1px #E5E5E5;
		margin: 0 auto;
	}
	.editor2 {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 12px;
	}
	.editor2 i{
		width: 5px;
		height: 14px;
		background: #F52C2E;
		border-radius: 5px;
	}
	.editor2 h1{
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #F52C2E;
		margin-left: 10px;
		margin-bottom: 3px;
		margin-top: 3px;
	}
	.editor1 .van-dropdown-menu__title{
		width: 100%;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		margin-bottom: 0px !important;
	}
	.editor3{
		width: 90%;
		display: flex;
		flex-direction: row;
		margin: 0 auto;
	}
	.editor3_1{
		width: 50%;
		min-height: 75px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-bottom: solid 1px #E5E5E5;
		margin: 0 auto;
	}
	.editor3_2 {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 12px;
	}
	.editor3_2 i{
		width: 5px;
		height: 14px;
		background: #F52C2E;
		border-radius: 5px;
	}
	.editor3_2 h1{
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #F52C2E;
		margin-left: 10px;
		margin-bottom: 3px;
		margin-top: 3px;
	}
	/* .editor3_1 span{
		width: 100%;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
	} */
	.Select .van-dropdown-menu__bar{
		width: 307px;
		height: 30px;
		background-color: #EEEEEE;
		box-shadow: 0 0px 0px rgba(100,101,102,.0);
		border-radius: 6px;
		margin-bottom: 10px;
	}
	.van-dropdown-menu__title::after{
		display: none;
	}
	.Select img{
		width: 7px;
		height: 13px;
		float: right;
		margin-top: -32px;
		position: relative;
		margin-right: 10px;
	}
	.shuru{
		width: 90%;
		height: 30px;
		background-color: #EEEEEE;
		border-radius: 6px;
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}
	.shuru input{
		border: none;
		background-color: #EEEEEE;
		font-size: 14px;
		margin-left: 5px;
		width: 100%;
	}
	.dhshuru{
		width: 90%;
		height: 60px;
		background-color: #EEEEEE;
		border-radius: 6px;
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}
	.dhshuru textarea{
		border: none;
		background-color: #EEEEEE;
		font-size: 14px;
		margin-left: 5px;
	}
	.fujian{
		margin-left: 20px;
	}
	.sqan1{
		width: 90%;
		height: 40px;
		margin: 20px auto;
		text-align: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
	}
	.sqan1 .save{
		width: 127px;
		height: 40px;
		border-radius: 10px;
		background-color: #F52C2E;
		text-align: center;
		color: #FFFFFF;
		font-size: 18px;
		font-weight: bold;
		border: none;
	}
	.sqan1 .delete{
		width: 127px;
		height: 40px;
		border-radius: 10px;
		background-color: #FFFFFF;
		text-align: center;
		color: #F52C2E;
		font-size: 18px;
		font-weight: bold;
		border: solid 1px #F52C2E;
	}
	#jssj .ivu-date-picker .ivu-select-dropdown{
		left: -75px !important;
	}
	.kssj{
		margin-bottom: 10px;
	}
</style>