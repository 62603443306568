import request from '@/utils/request'

// 获取我的数据
export const getXssqData = (periodType, courseName, startTime, endTime, courseHour, trainingOrgan, trainingLocation, fileName, remarks) => {
	return request.post('/client/user/classhour/save', {
		periodType,
		courseName,
		startTime,
		endTime,
		courseHour,
		trainingOrgan,
		trainingLocation,
		fileName,
		remarks
	})
}

export const getEditorData = (id, periodType, courseName, startTime, endTime, courseHour, trainingOrgan, trainingLocation, fileName, remarks) => {
	return request.post('/client/user/classhour/save', {
	id, 
	periodType, 
	courseName, 
	startTime, 
	endTime, 
	courseHour, 
	trainingOrgan, 
	trainingLocation,
	fileName,
	remarks, })
}

export const getUploadData = (formData) => {
	console.log(formData,1231)
	return request.post('/client/user/classhour/uploadFile',formData, {headers: {'Content-Type': 'multipart/form-data'}})
}

export const getYsqxsbjData = () => {
	return request.post('/client/user/classhour/save', {
	})
}

export const getKsqpxbData = () => {
	return request.post('/client/user/class/applyToJoin', {
	})
}


export const getYsqxslistData = (startTime, endTime) => {
	return request.get(`/client/user/classhour/applyHourList?startTime=${startTime}&endTime=${endTime}`, {
	})
}

export const getYsqpxblistData = (beginStartTime, endStartTime) => {
	return request.get(`/client/user/class/Apply?beginStartTime=${beginStartTime}&endStartTime=${endStartTime}`, {
		
	})
}

export const getKsqpxblistData = (beginApplyDeadline, endApplyDeadline) => {
	return request.get(`/client/user/class/enterableList?beginApplyDeadline=${beginApplyDeadline}&endApplyDeadline=${endApplyDeadline}`, {
		
	})
}