var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"van-nav-tb"},[_c('van-nav-bar',{attrs:{"title":"培训班详情","left-text":"","left-arrow":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}})],1),_c('div',{staticClass:"details"},[_c('div',{staticClass:"details1"},[_vm._m(0),_c('span',[_vm._v(_vm._s(_vm.detailData.className))])]),_vm._l((_vm.detralData),function(item){return _c('div',{key:item.id,staticClass:"details1"},[_vm._m(1,true),_c('span',[_vm._v(_vm._s(item.courseName))])])}),_c('div',{staticClass:"details1"},[_vm._m(2),_c('span',[_vm._v(_vm._s(_vm.detailData.trainingPlace))])]),_c('div',{staticClass:"details1"},[_vm._m(3),_c('span',[_vm._v(_vm._s(_vm.detailData.organizedBy))])]),_c('div',{staticClass:"details3"},[_c('div',{staticClass:"details3_1"},[_vm._m(4),_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.detailData.startTime,'YYYY-MM-DD')))])]),_c('div',{staticClass:"details3_1"},[_vm._m(5),_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.detailData.endTime,'YYYY-MM-DD')))])])])],2),_c('div',{staticClass:"sqan"},[_c('button',{on:{"click":function($event){_vm.show = true}}},[_vm._v("取  消")])]),_c('div',{staticStyle:{"height":"20px"}}),_c('div',[_c('van-dialog',{attrs:{"title":"是否取消该培训班","show-cancel-button":true,"confirmButtonText":"确认","cancelButtonText":"取消","cancle":"onCancle"},on:{"confirm":function($event){return _vm.deleteForm()}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],1),_c('GbFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details2"},[_c('i'),_c('h1',[_vm._v("培训班名称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details2"},[_c('i'),_c('h1',[_vm._v("课程名称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details2"},[_c('i'),_c('h1',[_vm._v("培训地点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details2"},[_c('i'),_c('h1',[_vm._v("主办单位")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details3_2"},[_c('i'),_c('h1',[_vm._v("开始时间")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details3_2"},[_c('i'),_c('h1',[_vm._v("结束时间")])])
}]

export { render, staticRenderFns }