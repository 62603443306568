<template>
  <div class="hello">
    <div class="van-nav-tb">
      <van-nav-bar title="首页" left-text="" left-arrow />
    </div>
    <div class="toububj">
      <router-link to="/tzgg">
        <img class="tbbjt" src="@/assets/sytoubu.png" alt="" />
        <div class="pxtz">
          <img src="@/assets/pxtz.png" alt="" />
          <i></i>
          <van-notice-bar
            v-if="index === 0"
            v-for="(item, index) in listTzgg"
            :key="item.id"
            :title="item.title"
            wrapable
            :scrollable="false"
            :text="item.title"
          />
        </div>
      </router-link>
    </div>
    <div style="height: 20px"></div>
    <div v-if="userInfo.roleNames == '学员'">
      <div class="hello_taba">
        <router-link to="/xscx/ycjpxblist">
          <div class="cjpxb">
            <h1>已参训培训班</h1>
            <span>已参加过的培训班</span>
            <a href="">立即查看</a>
          </div>
        </router-link>
        <div class="sqcx">
          <router-link to="/xscx">
            <div class="xscx">
              <h1>学时查询</h1>
              <span>查询培训班的学时</span>
            </div>
          </router-link>
          <router-link to="/xssq/ysqxslist">
            <div class="xssq">
              <h1>学时申请</h1>
              <span>申请学过培训班学时</span>
            </div>
          </router-link>
        </div>
      </div>
      <div class="pxb">
        <router-link to="/xssq/ksqpxblist">
          <img class="ksqpxb" src="@/assets/ksqpxb.jpg" alt="" />
        </router-link>
        <router-link to="/xssq/ysqpxblist">
          <img class="ysqpxb" src="@/assets/ysqpxb.jpg" alt="" />
        </router-link>
      </div>
    </div>

    <div class="pxb" v-if="userInfo.roleNames != '学员'">
      <router-link to="/cxl">
        <img class="ksqpxb" src="@/assets/cxl_img.png" alt="" />
      </router-link>
      <img style="opacity: 0" class="ysqpxb" src="" alt="" />
    </div>
    <!-- <GbFooter></GbFooter> -->
  </div>
</template>

<script>
import { getUserData } from "@/api/user";
import { getHomeData } from "@/api/home";

export default {
  name: "HomeIndex",
  data() {
    return {
      listTzgg: [],
      pageNo: 1, //当前
      pageSize: 10, //当前条数 如果3条就改成3
      title: "",
      userInfo: {},
    };
  },
  props: {
    msg: String,
  },
  created() {
    this.getList();
    this.judgeAuth();
  },
  methods: {
    // 获取列表
    async getList() {
      const res = await getHomeData(this.pageNo, this.pageSize);
      if (res.code === 401) {
        this.$toast(res.msg);
      }
      if (res.code !== 200) {
        this.$toast(res.msg);
      } else {
        this.listTzgg = res.data.list;
      }
    },

    async judgeAuth() {
      const res = await getUserData();

      this.userInfo = res.data;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.van-nav-bar__arrow {
  color: #000000 !important;
  font-size: 20px;
}
.hello {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.toububj {
  /* background: url("@/assets/sytoubu.png")100% no-repeat; */
  position: relative;
}
.tbbjt {
  width: 100%;
  height: auto;
}
.pxtz {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pxtz img {
  width: 40px;
  height: 41px;
  margin-left: 8%;
}
.pxtz i {
  background-color: #ffffff;
  margin-left: 10px;
  width: 1px;
  height: 40px;
  opacity: 0.5;
}
.van-notice-bar {
  width: 71%;
  color: #ffffff;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 0);
  padding: 8px 6px;
}
.van-notice-bar__content {
  text-align: left !important;
}
.hello_taba {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 10px auto;
}
.cjpxb {
  width: 172px;
  height: 163px;
  display: flex;
  flex-direction: column;
  background: url("@/assets/ktpx.png") 100% no-repeat;
  background-size: 100% 100%;
}
.cjpxb h1 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ce8355;
  line-height: 16px;
  margin-left: 15px;
  margin-top: 17px;
}
.cjpxb span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ce8355;
  line-height: 14px;
  margin-left: 15px;
  margin-top: 5px;
}
.cjpxb a {
  width: 68px;
  height: 20px;
  background: #fb8346;
  border-radius: 16px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
  text-align: center;
  margin-left: 15px;
  margin-top: 10px;
}
.sqcx {
  display: flex;
  flex-direction: column;
}
.sqcx .xscx {
  width: 175px;
  height: 74px;
  display: flex;
  flex-direction: column;
  background: url("@/assets/xscx.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 4px;
}
.sqcx .xscx h1 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fb7162;
  line-height: 18px;
  margin-left: 10px;
  margin-top: 15px;
}
.sqcx .xscx span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fb7162;
  line-height: 14px;
  margin-left: 10px;
  margin-top: 5px;
}
.sqcx .xssq {
  width: 175px;
  height: 74px;
  display: flex;
  flex-direction: column;
  background: url("@/assets/xssq.png") no-repeat;
  margin-top: 15px;
  background-size: 100% 100%;
  border-radius: 4px;
}
.sqcx .xssq h1 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #d7a143;
  line-height: 18px;
  margin-left: 10px;
  margin-top: 15px;
}
.sqcx .xssq span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d7a143;
  line-height: 14px;
  margin-left: 10px;
  margin-top: 5px;
}
.pxb {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 5px auto;
}
.pxb img {
  width: 174px;
  height: 74px;
}
</style>
<!-- <style scoped>
	.van-nav-bar__arrow {
		color: #000000 !important;
		font-size: 20px;
	}
	.hello{
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
	}
	.toububj{
		position: relative;
	}
	.tbbjt{
		width: 100%;
		height: auto;
	}
	.pxtz{
		position: absolute;
		left: 23px;
		bottom: 18px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.pxtz img{
		width: 40px;
		height: 41px;
		margin-left: 7%;
	}
	.pxtz i{
		background-color: #FFFFFF;
		margin-left: 10px;
		width: 1px;
		height: 40px;
		opacity: 0.5;
	}
	.van-notice-bar{
		width: 71%;
		color: #FFFFFF;
		line-height: 20px;
		background-color: rgba(255, 255, 255, 0.0);
		padding: 8px 6px;
	}
	.van-notice-bar__content{
		text-align: left !important;
	}
	.hello_taba{
		width: 85%;
		height: auto;
		display: flex;
		flex-direction: row;
		margin: 10px auto;
	}
	.cjpxb{
		width: 172px;
		height: 163px;
		display: flex;
		flex-direction: column;
		background: url("@/assets/ktpx.png")100% no-repeat;
		background-size: 100% 100%;
	}
	.cjpxb h1{
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #CE8355;
		line-height: 16px;
		margin-left: 15px;
		margin-top: 17px;
	}
	.cjpxb span{
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #CE8355;
		line-height: 14px;
		margin-left: 15px;
		margin-top: 5px;
	}
	.cjpxb a{
		width: 68px;
		height: 20px;
		background: #FB8346;
		border-radius: 16px;
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 18px;
		text-align: center;
		margin-left: 15px;
		margin-top: 10px;
	}
	.sqcx{
		display: flex;
		flex-direction: column;
		margin-left: 3%;
	}
	.sqcx .xscx{
		width: 175px;
		height: 74px;
		display: flex;
		flex-direction: column;
		background: url("@/assets/xscx.png") no-repeat;
		background-size: 100% 100%;
		border-radius: 4px;
	}
	.sqcx .xscx h1{
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #FB7162;
		line-height: 18px;
		margin-left: 10px;
		margin-top: 15px;
	}
	.sqcx .xscx span{
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #FB7162;
		line-height: 14px;
		margin-left: 10px;
		margin-top: 5px;
	}
	.sqcx .xssq{
		width: 175px;
		height: 74px;
		display: flex;
		flex-direction: column;
		background: url("@/assets/xssq.png") no-repeat;
		margin-top: 15px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
	.sqcx .xssq h1{
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #D7A143;
		line-height: 18px;
		margin-left: 10px;
		margin-top: 15px;
	}
	.sqcx .xssq span{
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #D7A143;
		line-height: 14px;
		margin-left: 10px;
		margin-top: 5px;
	}
	.pxb{
		width: 87%;
		height: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin: 5px auto;
	}
	.pxb img{
		width: 174px;
		height: 74px;
	}
</style -->
>
