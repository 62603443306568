<template>
  <div>
    <div class="van-nav-tb">
      <van-nav-bar
        title="参训率"
        left-text=""
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    
    <div class="filter">
      <DatePicker :value="params.year" type="year" placeholder="选择年份" style="width: 200px" @on-change="changeYear" />
      <div class="btn" v-if="!isloading" @click="onSearch">查询</div>
      <div class="btn" v-else>查询</div>
      <div class="btn" @click="onComputed">参训率</div>
    </div>
    <div class="computed">
      <span>干部人数：{{ info.rs }}</span>
      <span>合格率：{{ info.rate }}</span>
    </div>
    <!-- 列表 -->
    <div class="list">
      <van-checkbox-group  v-model="result">
        <van-checkbox shape="square" class="item" :name="item.user?.id" v-for="(item,index) in list" :key="index">
          <div class="inner">
            <div class="title">学员姓名：{{ item.user.name }}</div>
            <div class="desc">职务：{{ item.user.duty }}</div>
            <div>学时：{{item.classHour}}学时</div>
          </div>
        </van-checkbox>
      </van-checkbox-group>

      <div class="load">
        <div v-if="total > list.length" @click="loadMore">加载更多+</div>
        <div v-else>没有更多了...</div>
      </div>

    </div>
  </div>
</template>

<script>
import { listCxl, computedCxl } from '@/api/cxl'
export default {
  name: "CxlIndex",
  data() {
    return {
      info: {
        rs: 0,
        rate: '0%'
      },
      isloading: false,
      result: [], // 复选框组
      params: {
        pageNo: 1,
        pageSize: 5,
        year: new Date().getFullYear().toString()
      },
      list: [],
      total: 0,
      computedParams: {
        userIds: [],
        year: []
      }
    };
  },
  mounted() {
    this.getCxlList()
  },
  methods: {

    changeYear(e) {
      this.params = {
        ...this.params,
        year: e
      }
      
    },

    // 获取参训率列表
    async getCxlList() {
      const res = await listCxl(this.params)
      this.total = res.data.count

      if(res.data.list) {
        this.list = [...this.list, ...res.data.list]
      } else {
        this.list = []
      }
      this.isloading = false
      
      
    },
 

    onSearch() {
      this.isloading = true

      console.log(123);
      
      this.params = {
        ...this.params,
        pageNo: 1,
      }

      this.list = []

      this.getCxlList()

      
    },
    async onComputed() {
      const params = {
        userIds: this.result,
        year: this.params.year
      }
      const res = await computedCxl(JSON.stringify(params))

      this.info = res.data
      
    },
    loadMore() {
      this.params.pageNo = this.params.pageNo + 1

      this.getCxlList()
      
    }


  },
};
</script>

<style scoped>

.filter {
  display: flex;
  align-items: center;
  padding: 30px 20px 0 20px;
}

.filter .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 32px;
  background-color: #f52c2e;
  color: #fff;
  font-size: 16px;
  margin-left: 20px;
  border-radius: 10px;
}

.computed {
  margin-top: 10px;
  padding: 0 20px;
}

.computed span {
  margin-right: 20px;
}

.list {
  width: 100%;
  padding: 30px 20px;
}

.item {
  width: 100%;
  height: 100px;
  background-color: #bfc;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  padding: 0 20px;
  border-radius: 10px;
}

.inner {
  padding-left: 10px;
}

.item .title {
  font-size: 16px;
  color: #333;
  font-weight: 700;
  margin-bottom: 5px;
}

.item .desc {
  font-size: 14px;
  margin-bottom: 5px;
}

.load {
  padding-bottom: 30px;
  text-align: center;
  font-size: 18px;
}
</style>
