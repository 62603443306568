var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"van-nav-tb"},[_c('van-nav-bar',{attrs:{"title":"学时查询详情","left-text":"","left-arrow":""},on:{"click-left":function($event){return _vm.$router.go(-1)}}})],1),_c('div',{staticClass:"details"},[_c('div',{staticClass:"details1"},[_vm._m(0),_c('span',[_vm._v(_vm._s(_vm.detralData.className))])]),_c('div',{staticClass:"details1"},[_vm._m(1),_c('span',[_vm._v(_vm._s(_vm.detailData.courseName))])]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"details3"},[_c('div',{staticClass:"details3_1"},[_vm._m(4),_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.detailData.startTime,"YYYY-MM-DD")))])]),_c('div',{staticClass:"details3_1"},[_vm._m(5),_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.detailData.endTime,"YYYY-MM-DD")))])])])]),_c('div',{staticStyle:{"height":"20px"}}),_c('GbFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details2"},[_c('i'),_c('h1',[_vm._v("培训班名称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details2"},[_c('i'),_c('h1',[_vm._v("课程名称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details1"},[_c('div',{staticClass:"details2"},[_c('i'),_c('h1',[_vm._v("培训地点")])]),_c('span',[_vm._v("呼和浩特市")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details1"},[_c('div',{staticClass:"details2"},[_c('i'),_c('h1',[_vm._v("主办单位")])]),_c('span',[_vm._v("市委组织部")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details3_2"},[_c('i'),_c('h1',[_vm._v("开始时间")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details3_2"},[_c('i'),_c('h1',[_vm._v("结束时间")])])
}]

export { render, staticRenderFns }