<template>
	<div class="page">
		<div class="van-nav-tb">
			<van-nav-bar
				title="培训班详情"
				left-text=""
				left-arrow
				@click-left="$router.go(-1)"
			/>
		</div>
		<div class="details">
			<div class="details1">
				<div class="details2">
					<i></i>
					<h1>培训班名称</h1>
				</div>
				<span>{{ detailData.className }}</span>
			</div>
			<div class="details1" v-for="item in detralData" :key="item.id">
				<div class="details2">
					<i></i>
					<h1>课程名称</h1>
				</div>
				<span>{{ item.courseName }}</span>
			</div>
			<div class="details1">
				<div class="details2">
					<i></i>
					<h1>培训地点</h1>
				</div>
				<span>{{ detailData.trainingPlace}}</span>
			</div>
			<div class="details1">
				<div class="details2">
					<i></i>
					<h1>主办单位</h1>
				</div>
				<span>{{ detailData.organizedBy }}</span>
			</div>
			<div class="details3">
				<div class="details3_1">
					<div class="details3_2">
						<i></i>
						<h1>开始时间</h1>
					</div>
					<span>{{ detailData.startTime | date('YYYY-MM-DD') }}</span>
				</div>
				<div class="details3_1">
					<div class="details3_2">
						<i></i>
						<h1>结束时间</h1>
					</div>
					<span>{{ detailData.endTime | date('YYYY-MM-DD') }}</span>
				</div>
			</div>
		</div>
		<div class="sqan">
			<button @click="show = true">取&nbsp;&nbsp;消</button>
		</div>
		<div style="height: 20px;"></div>
		<!-- Dialog弹窗 -->
		<div>
			<van-dialog v-model="show" title="是否取消该培训班" 
			:show-cancel-button="true" 
			confirmButtonText="确认" 
			cancelButtonText="取消" 
			@confirm="deleteForm()" 
			cancle="onCancle">
			</van-dialog>
		</div>
		<GbFooter></GbFooter>
	</div>
</template>

<script>
	import request from '@/utils/request'
	export default {
		name: 'DshxqIndex',
		data() {
			return {
				id:'',
				detailData: '' ,// 用于存储从详情接口获取的数据
				detralData: [] ,// 用于存储从详情接口获取的数据
				show: false
			};
		},
		created() {
			if(this.$route.query){
				this.id = this.$route.query.id;
				this.fetchDetailData(); // 在组件创建时发送请求获取详情数据
			}
		},
		methods: {
		    async fetchDetailData() {
		      try {
		        // 发送GET请求到详情接口，使用id作为查询参数
		        const response = await request.get(`/client/user/class/classInfo?classId=${this.id}`);
		        this.detailData = response.data; // 将获取的数据存储在detailData中
				this.detralData = response.data.trainingClassPeriodList; // 将获取的数据存储在detralData中
				console.log(this.detailData)
		      } catch (error) {
		        console.error('Error fetching detail data:', error);
		      }
		    },
			async deleteForm () {
				// 发送GET请求到详情接口，使用id作为查询参数
				const response = await request.get(`/client/user/class/cancelApplyToJoin?id=${this.id}`);
				if(response.code==200){
					// 删除成功，显示成功消息或跳转到其他页面
					this.$toast('取消成功');
					const url = this.$route.query.backUrl || '/';
					this.$router.replace(url);
				}else{
					// 其他消息，根据msg信息进行相应处理
					this.$toast(response.msg);
				}		
			},
		},
	};
</script>

<style>
	.page{
		width: 100%;
		height: 100vh;
		background-color: #EFEFEF;
	}
	.details{
		width: 90%;
		min-height: 380px;
		background: #FFFFFF;
		box-shadow: 0px 1px 18px 0px rgba(0,0,0,0.1);
		border-radius: 10px;
		margin: 20px auto;
	}
	.details1{
		width: 90%;
		min-height: 75px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-bottom: solid 1px #E5E5E5;
		margin: 0 auto;
	}
	.details2 {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 12px;
	}
	.details2 i{
		width: 5px;
		height: 14px;
		background: #F52C2E;
		border-radius: 5px;
	}
	.details2 h1{
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #F52C2E;
		margin-left: 10px;
		margin-bottom: 3px;
		margin-top: 3px;
	}
	.details1 span{
		width: 100%;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		margin-bottom: 10px;
	}
	.details3{
		width: 90%;
		display: flex;
		flex-direction: row;
		margin: 0 auto;
	}
	.details3_1{
		width: 50%;
		min-height: 75px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-bottom: solid 1px #E5E5E5;
		margin: 0 auto;
	}
	.details3_2 {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 12px;
	}
	.details3_2 i{
		width: 5px;
		height: 14px;
		background: #F52C2E;
		border-radius: 5px;
	}
	.details3_2 h1{
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #F52C2E;
		margin-left: 10px;
		margin-bottom: 3px;
		margin-top: 3px;
	}
	.details3_1 span{
		width: 100%;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
	}
	.sqan{
		width: 90%;
		height: 40px;
		margin: 20px auto;
		text-align: center;
	}
	.sqan button{
		width: 200px;
		height: 40px;
		border-radius: 50px;
		background-color: #F52C2E;
		text-align: center;
		color: #FFFFFF;
		font-size: 18px;
		font-weight: bold;
		border: none;
	}
</style>