import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login'
import Layout from '@/views/layout'
import Home from '@/views/layout/home'
import User from '@/views/layout/user'
import Xscx from '@/views/xscx'
import Xscxxq from '@/views/xscx/xscxxq'
import Ycjpxbxq from '@/views/xscx/ycjpxbxq'
import Ycjpxblist from '@/views/xscx/ycjpxblist'
import XscxListyc from '@/views/xscx/xscxlistyc'
import Xssq from '@/views/xssq'
import Ksqpxblist from '@/views/xssq/ksqpxblist'
import Ysqpxblist from '@/views/xssq/ysqpxblist'
import Dshxq from '@/views/xssq/dshxq'
import Editor from '@/views/xssq/editor'
import Ysqxslist from '@/views/xssq/ysqxslist'
import Ysqxsbj from '@/views/xssq/ysqxsbj'
import Tzgg from '@/views/tzgg'
import TzggContent from '@/views/tzgg/content'
import Xgmm from '@/views/xgmm'
import Cxl from '@/views/cxl'

// import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  // /article 路径 → Article组件
  routes: [
	{
		path: '/login',
		component: Login
	},
	{
		path: '/',
		component: Layout,
		redirect: "/home",
		children: [
			{
				path: '/user',
				component: User
			},
			{
				path: '/home',
				component: Home
			}
		]
	},
	{
		path: '/xscx',
		component: Xscx
	},
	{
		path: '/xscx/ycjpxbxq/:id?',
		component: Ycjpxbxq
	},
	{
		path: '/xscx/ycjpxblist',
		component: Ycjpxblist
	},
	{
		path: '/xscx/xscxlistyc',
		component: XscxListyc
	},
	{
		path: '/xscx',
		component: Xscx
	},
	{
		path: '/xscx/xscxxq/:id?',
		component: Xscxxq
	},
	{
		path: '/xssq',
		component: Xssq
	},
	{
		path: '/xssq/dshxq/:id?',
		component: Dshxq
	},
	{
		path: '/xssq/editor/:id?',
		component: Editor
	},
	{
		path: '/xssq/ysqpxblist',
		component: Ysqpxblist
	},
	{
		path: '/xssq/ksqpxblist',
		component: Ksqpxblist
	},
	{
		path: '/xssq/ysqxslist',
		component: Ysqxslist
	},
	{
		path: '/xssq/ysqxsbj/:id?',
		component: Ysqxsbj
	},
	{
		path: '/tzgg',
		component: Tzgg
	},
	{
		path: '/tzgg/content/:id?',
		component: TzggContent
	},
	{
		path: '/xgmm',
		component: Xgmm
	},
	{
		path: '/cxl',
		component: Cxl
	},
  ]
})

// 所有的路由在真正被访问到之前(解析渲染对应组件页面前)，都会先经过全局前置守卫
// 只有全局前置守卫放行了，才会到达对应的页面

// 全局前置导航守卫
// to:   到哪里去，到哪去的完整路由信息对象 (路径，参数)
// from: 从哪里来，从哪来的完整路由信息对象 (路径，参数)
// next(): 是否放行
// (1) next()     直接放行，放行到to要去的路径
// (2) next(路径)  进行拦截，拦截到next里面配置的路径

// 定义一个数组，专门用户存放所有需要权限访问的页面
const authUrls = []


router.beforeEach((to, from, next) => {
  if (!authUrls.includes(to.path)) {
    // 非权限页面，直接放行
    next()
    return
  }

  // 获取 token
  const token = localStorage.getItem('token')
  console.log(token,99999)
  if (token) {
    // 如果 token 存在，则放行
    next()
  } else {
    // 如果 token 不存在，则重定向到登录页面并显示提示消息
    next({ path: '/login', message: '您需要登录才能访问该页面' })
  }
})

export default router