import { getToken, setToken } from "@/utils/token";
export default {
  namespaced:true,//作用域问题
  state:{
    toke:getToken(),
    user:''//用户信息
  },
  mutations:{
    // 设置token
    setToken(state,token) {
		setToken(token);
		state.token = token
    },
    // 清除token
    clearToken(state) {
      state.token = ""
    },
    // 设置用户
    setUser(state,value) {
      state.user = value
    },
    // 清除用户
    clearUser(state) {
      state.user = ""
    }
  }
}

// import { getInfo, setInfo } from '@/utils/storage'

// export default {
// 	namespaced: true,
// 	state () {
// 		return {
// 			// 个人权证相关
// 			userInfo: getInfo()
// 		}
// 	},
// 	mutations: {
// 		// 所有mutations的第一个参数，都是state
// 		setUserInfo (state, obj) {
// 			state.userInfo = obj
// 			setInfo(obj)
// 		}
// 	},
// 	actions: {
// 		logout (context) {
// 			// 个人信息要重置
// 			context.commit('setUserInfo', {})
// 		}
// 	},
// 	getters: {}
// }