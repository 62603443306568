//在index.js中引入模块
/* index.js */
import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user'

Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    user,
  },
  // getters
})