<template>
	<div class="">
		<div class="van-nav-tb">
			<van-nav-bar
				title="学时查询"
				left-text=""
				left-arrow
				@click-left="$router.go(-1)"
			/>
		</div>
		<div class="xscx">
			<div style="height: 10px;"></div>
			<div class="sous">
				<div class="xsxx">
					<div style="width: 10px;"></div>
					<h5>应学学时</h5>
					<span>{{ listXscxs.learnHour }}</span>
					<h5>已学学时</h5>
					<span>{{ listXscxs.totalHour }}</span>
					<h5>网络学时</h5>
					<span>{{ listXscxs.totalTempHour }}</span>
					<h5>剩余学时</h5>
					<span>{{ listXscxs.theRestHour }}</span>
				</div>
				<div class="sousuo">
					<div class="sjxz">
						<div class="qssj">
							<Row>
								<Col span="12">
									<DatePicker type="date" v-model="value" @input="convertDateFormat" placeholder="起始时间" format="yyyy/MM/dd" style="width: 130px"></DatePicker>
								</Col>
							</Row>
						</div>
						<div class="jssj">
							<Row>
								<Col span="12">
									<DatePicker type="date" v-model="value1" @input="convertDateFormatend" placeholder="结束时间" format="yyyy/MM/dd" style="width: 130px"></DatePicker>
								</Col>
							</Row>
						</div>										
					</div>
					<div class="battn_ss" @click="searchData">搜索</div>
				</div>				
			</div>
			<div class="xscx_list" v-for="item in listXscx" :key="item.id" @click="skipclick(item)" >
				<div class="btrq">
					<h1>{{ item.period.courseName }}</h1>
					<!-- <span class="ytg wtg">已通过</span> -->
				</div>
				<div class="sjlist">
					<div class="sjlist_1">
						<h5>开始时间</h5>
						<span>{{ item.period.startTime | date('YYYY-MM-DD') }}</span>
					</div>
					<div class="sjlist_1">
						<h5>结束时间</h5>
						<span>{{ item.period.endTime | date('YYYY-MM-DD') }}</span>
					</div>
					<div class="sjlist_1">
						<h5>总学时</h5>
						<span>{{ item.period.periodHour }}</span>
					</div>
				</div>
				<div class="lywz">
					<div class="ly">
						<img src="@/assets/sgdw.png" alt="" />
						<span>{{ item.organizedBy }}</span>
					</div>
					<div class="wz">
						<img src="@/assets/wz2.png" alt="" />
						<span>{{ item.trainingPlace }}</span>
					</div>
				</div>
			</div>
			<div style="height: 50px;"></div>
		</div>
		<GbFooter></GbFooter>
	</div>
</template>

<script>
	// import { turn } from 'core-js/fn/array';
	// import { Toast } from 'vant';
	import { getXscxData } from '@/api/xscx'
	
	export default {
		name: 'XscxIndex',
		data() {
			return {
				value: '',
				date: '',
				value1: '',
				listXscx: [],
				listXscxs: [],
				convertedDate:'2010/01/01',
				convertedDateend:'2050/01/01',
				learnHour:'90',
				totalHour: '',
				totalTempHour: '',
				theRestHour: ''
			};
		},
		created () {
			this.getList()
			// console.log(localStorage.getItem('token'),111)
		},
		methods: {
			convertDateFormat() {
			    const date = new Date(this.value);
			    this.convertedDate = date.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/-/g, '/');
			},
			convertDateFormatend() {
			    const date = new Date(this.value1);
			    this.convertedDateend = date.toLocaleString('zh-CN', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/-/g, '/');
			},
			// 获取列表
			async getList() {
				const res = await getXscxData( this.convertedDate,  this.convertedDateend, this.learnHour)
				// console.log(res.data,99999)
				this.listXscx = res.data.list
				this.listXscxs = res
				// this.classHour = res.res
				   // const filteredData = this.listXscx.filter(item => {
				   //     // 检查起始时间和结束时间是否在 item 的时间范围内
				   //     const itemStartTime = new Date(item.startTime);
				   //     const itemEndTime = new Date(item.endTime);
				   //     const convertedDate = new Date(this.convertedDate);
				   //     const convertedDateend = new Date(this.convertedDateend);
				       
				   //     // 检查起始时间和结束时间是否在 item 的时间范围内
				   //     return itemStartTime <= convertedDate && itemEndTime >= convertedDateend;
				   // });
							// 	// 将搜索到的数据赋值给 this.filteredData
							// 	this.filteredData = filteredData;
				   // console.log(this.filteredData,22222222);
				   // // 将搜索到的数据渲染到页面上
				   // // 假设你有一个名为 displayData 的变量来存储渲染后的数据
				   // this.listXscx = filteredData;
			},
			// 搜索
			searchData() {
				this.getList()
			},

			skipclick(item){
				this.$router.push({
					path:'/xscx/xscxxq',
					query:{id:item.id}
				})
			}
		},

	};
</script>

<style scoped>
	.van-nav-bar__arrow {
		color: #000000 !important;
		font-size: 20px;
	}
	.xsxx{
		width: 95%;
		height: 45px;
		background: #F1F1F1;
		margin: 10px auto;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.xsxx h5{
		width: 40px;
		height: 40px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
	}
	.xsxx span{
		width: 45px;
		height: 25px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #F52C2E;
	}
	.xscx{
		width: 100%;
		min-height: 400px;
		/* background-color: #EFEFEF; */
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
	}
	.sous{
		width: 90%;
		min-height: 123px;
		background: #FFFFFF;
		border-radius: 10px;
		margin: 10px auto;
	}
	.sousuo{
		display: flex;
		flex-direction: row;
		/* width: 100%; */
		margin-top: 15px;
		align-items: center;
	}
	/deep/.van-search__content{
		border-radius: 50px;
		background-color: #FFFFFF;
		border: solid 1px #DCDCDC;
		/* width: 80%; */
	}
	/deep/.van-cell{
		padding: 0px 10px;
		background: rgba(255,255,255,0);
	}
	.battn_ss{
		width: 57px;
		height: 28px;
		background: #F52C2E;
		border-radius: 28px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 26px;
		color: #FFFFFF;
		text-align: center;
		margin-left: 10px;
	}
	.sjxz{
		display: flex;
		flex-direction: row;
		/* margin-bottom: 20px; */
		margin-left: 15px;
	}
	.qssj{
		/* width: 160px;
		height: 28px;
		background: rgba(255,255,255,0);
		border: 1px solid #DCDCDC;
		border-radius: 28px;
		display: flex;
		flex-direction: row;
		align-items: center; */
	}
	.qssj img{
		width: 12px;
		height: 12px;
		margin-right: 10px;
	}
	.jssj{
		/* width: 160px;
		height: 28px;
		background: rgba(255,255,255,0);
		border: 1px solid #DCDCDC;
		border-radius: 28px;
		display: flex;
		flex-direction: row;
		align-items: center; */
		margin-left: 10px;
	}
	.jssj img{
		width: 12px;
		height: 12px;
		margin-right: 10px;
	}
	#jssj .ivu-date-picker .ivu-select-dropdown{
		left: -75px !important;
	}
	.xscx_list{
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 90%;
		height: 150px;
		background: #FFFFFF;
		box-shadow: 0px 1px 18px 0px rgba(0,0,0,0.1);
		border-radius: 10px;
		margin: 10px auto;
		/* margin-top: 20px; */
	}
	.btrq{
		width: 90%;
		height: 30px;
		display: flex;
		flex-direction: row;
		margin-top: 13px;
		align-items: center;
		border-bottom: solid 1px #EEEEEE;
	}
	.btrq h1{
		font-weight: bold;
		color: #F52C2E;
		width: 95%;
		height: 16px;
		font-size: 16px;
		line-height: 16px;
		font-family: Microsoft YaHei;
		margin: 0;
		white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
	}
	.sjlist{
		width: 90%;
		height: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 0 auto;
	}
	.sjlist_1{
		width: 32%;
		height: auto;
		display: flex;
		flex-direction: column;
	}
	.sjlist_1 h5{
		font-size: 15px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		margin: 0;
	}
	.sjlist_1 span{
		font-size: 12px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin-top: 5px;
		height: 15px;
	}
	.lywz{
		width: 90%;
		height: 29px;
		background: #FFF2F2;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 10px;
	}
	.ly{
		width: auto;
		height: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.ly img{
		width: 13px;
		height: 13px;
		margin-left: 10px;
	}
	.ly span{
		width: 90px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		margin-left: 10px;
		white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
	}
	.wz{
		width: auto;
		height: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: 5px;		
	}
	.wz img{
		width: 13px;
		height: 13px;
		margin-left: 10px;
	}
	.wz span{
		width: 165px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		margin-left: 10px;
		white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
	}
</style>