<template>
	<div class="page_xgmm">
		<div class="van-nav-tb">
			<van-nav-bar
				title="修改密码"
				left-text=""
				left-arrow
				@click-left="$router.go(-1)"
			/>
		</div>
		<div class="xgmm">
			<span class="xgmm_span">手机号</span>
			<input class="xgmm_input" type="text" v-model="username" placeholder="请输入手机号" />
			<span class="xgmm_span">密码</span>
			<input class="xgmm_input" type="text" v-model="password" placeholder="请输入密码" />
			<span class="xgmm_span">确认密码</span>
			<input class="xgmm_input" type="text" v-model="confirmPassword" placeholder="请输入新密码" />
			<span class="xgmm_span">验证码</span>
			<div class="fsyzm">
				<input class="fsyzm_input" type="text" v-model="code" placeholder="请输入验证码" />
				<button class="send-code-button" style="border: none;" @click="sendCode" :disabled="isDisabled">{{ countdown ? countdown + 's后重新发送' : '发送验证码' }}</button>
			</div>
			
			<button class="xgmm_button" @click="edit" >保&nbsp;&nbsp;存</button>
			
			<p v-if="message">{{ message }}</p>
		</div>
	</div>
</template>

<script>
	import { xgmmEdit, fsyzmEdit } from '@/api/xgmm';
	import request from '@/utils/request'
	export default {
		name: 'XgmmIndex',
		data() {
			return {
				valuel: '',
				valuel1: '',
				username: '',
				password: '',
				confirmPassword: '',
				code: '',
				message: '',
				uuid: '',
				isDisabled: false, // 控制发送按钮是否可点击
				countdown: null, // 倒计时
				timer: null, // 定时器
			};
		},
		methods: {
			async sendCode() {
			  if (!this.username) {
				this.$toast('请先输入手机号');
				return;
			  }
			  if (!this.isDisabled) {
				try {
				  const res = await fsyzmEdit(this.username);
				  if (res.code === 200) {
					this.$toast('发送验证码成功');
					this.uuid = res.uuid;
				  } else {
					this.$toast(res.msg);
				  }
				  
				  this.isDisabled = true;
				  this.countdown = 60;
				  this.timer = setInterval(() => {
					if (this.countdown <= 0) {
					  clearInterval(this.timer);
					  this.isDisabled = false;
					  this.countdown = null;
					} else {
					  this.countdown--;
					}
				  }, 1000);
				} catch (error) {
				  // 处理网络错误或其他异常
				  this.$toast('发送验证码失败，请稍后再试');
				}
			  }
			},
			async edit () {
				if (!this.username) {
					this.$toast('请输入手机号');
					return;
				}
				if (!this.password) {
					this.$toast('请输入密码');
					return;
				}
				if (!this.confirmPassword) {
					this.$toast('请输入确认密码');
					return;
				}
				if (!this.code) {
					this.$toast('请输入验证码');
					return;
				}
				try {
					const username = this.username
					const password = this.password
					const confirmPassword = this.confirmPassword
					const code = this.code
					const uuid = this.uuid
					const formData = {username, password, confirmPassword, code, uuid};
					const res = await request({
					  url: '/client/resetPwd', // 你的接口URL
					  method: 'POST',
					  data: {
						username: username, // 注意：这里可能是个拼写错误，应该是phoneNumber
						password: password,
						confirmPassword: confirmPassword,
						code: code,
						uuid: uuid,
					  },
					  headers: {
						'content-type': 'application/json', // 默认值
					  }
					});	
					// 处理响应结果
					if (res.code === 200) {
					  // 请求成功，处理成功逻辑
					  // 更新成功，显示成功消息或跳转到其他页面
					  this.$toast('修改成功');
					  const url = this.$route.query.backUrl || '/';
					  this.$router.replace(url);
					} else {
					  // 请求失败，处理错误逻辑
					  // 其他消息，根据msg信息进行相应处理
					  this.$toast(res.msg);
					}
				} catch (error) {
					// 处理请求错误
					console.error('请求失败:', error);
					// 处理网络错误或其他异常
					this.$toast(res.msg);
				}
			}
		},
	};
</script>

<style>
	button{
		border: none;
		outline: none;
	}
	.fsyzm{
		width: 80%;
		height: 40px;
		border-radius: 5px;
		background-color: #ffffff;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.send-code-button{
		width: 95px;
		height: 25px;
		border-radius: 50px;
		background-color: #F9FAFE;
		text-align: center;
		color: #666666;
		font-size: 12px;
		line-height: 25px;
		margin-right: 10px;
		/* border: solid 1px rgba(68,129,254,0.3); */
		box-shadow: 0px 0px 1px 0px rgba(68,129,254,0.3);
		padding: 0px;
	}
	.page_xgmm {
		width: 100%;
		height: 100vh;
		background-color: #EFEFEF;
	}
	.van-nav-bar__arrow {
		color: #000000 !important;
		font-size: 20px;
	}
	.xgmm{
		width: 70%;
		height: auto;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
	}
	.xgmm_span{
		width: 80%;
		margin-left: 30px;
		font-size: 16px;
		color: #333333;
		margin-top: 30px;
		margin-bottom: 10px;
	}
	.fsyzm_input{
		width: 60%;
		height: 30px;
		border: none;
		color: #333333;
		font-size: 14px;
		padding-left: 10px;
	}
	.xgmm_input{
		width: 80%;
		height: 30px;
		border: none;
		border-radius: 5px;
		color: #333333;
		font-size: 14px;
		padding-left: 10px;
		margin: 0 auto;
	}
	.xgmm_button{
		width: 90px;
		height: 35px;
		border: none;
		background-color: #F52C2E;
		border-radius: 50px;
		margin: 30px auto;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		color: #FFFFFF;
	}
</style>