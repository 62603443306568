<template>
	<van-tabbar v-model="active" active-color="#FF496F" inactive-color="#999" route>
		<van-tabbar-item to="/">
			<span>首页</span>
			<template #icon="props">
				<img :src="props.active ? icon.active : icon.inactive" />
			</template>
		</van-tabbar-item>
		<van-tabbar-item to="/user">
			<span>我的</span>
			<template #icon="props">
				<img :src="props.active ? icons.active : icons.inactive" />
			</template>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
	export default {
		data() {
			return {
				active: 0,
				// mine: 0,
				icon: {
					active: require('../assets/sy.png'),
					inactive: require('../assets/sy1.png'),
				},
				icons: {
					active: require('../assets/wd.png'),
					inactive: require('../assets/wd1.png'),
				},
			};
		},
	};
</script>

<style scoped>
/deep/.van-tabbar-item{
	/* color: #999999 !important; */
	flex-direction: row;
	font-size: 16px;
}
/deep/.van-tabbar-item--active {
	/* color: #999999 !important; */
	background-color: #fff;
}
/deep/.van-tabbar-item__text{
	margin-left: 10px;
}
</style>