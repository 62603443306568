// 文件核心作用:导入App.vue,基于App.vue创建结构渲染index.html
// 1.导入vue核心包
import Vue from 'vue'
import store from './store/index.js'

// 2.导入App.vue根组件
import App from './App.vue'
import router from './router'
import GbHeader from './components/GbHeader.vue'
import GbFooter from './components/GbFooter.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import VueDateFilter from 'vue-date-filter';

Vue.use(Vant);
Vue.use(ViewUI);
Vue.use(VueDateFilter);
// 提示:当前处于什么环境(生产环境/开发环境)
Vue.config.productionTip = false

// 进行全局注册--在所有组件范围内都能使用
Vue.component('GbHeader',GbHeader)
Vue.component('GbFooter',GbFooter)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
