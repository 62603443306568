<template>
	<div>
		<div class="van-nav-tb">
			<van-nav-bar
				title="培训通知"
				left-text=""
				left-arrow
				@click-left="$router.go(-1)"
			/>
		</div>
		<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
			<van-list
				v-model="loading"
				:finished="finished"
				 :finished-text="finishedText"
				@load="onLoads"
			>
				<van-cell v-for="item in listTzgg" :key="item.id" :title="item.title" @click="handelclick(item)" />
			</van-list>
			<div style="height: 50px;"></div>
		</van-pull-refresh>
		<GbFooter></GbFooter>
	</div>
</template>

<script>
	import { getTzggData } from '@/api/tzgg'
	export default {
		name: 'TzggIndex',
		data() {
			return {
				listTzgg: [],
				loading: false,
				finished: false,
				refreshing: false,
				pageNo:1, //当前
				pageSize:10,//当前条数 如果3条就改成3
				title:'',
				count:'',
				finishedText:'下拉加载更多'
			};
		},
		created() {
			this.getList()
		},
		
		methods: {
			// 获取列表
			async getList() {
				let that = this
				const res = await getTzggData(that.pageNo, that.pageSize)
				that.pageSize =  res.data.pageSize
				that.count = res.data.count
				if(this.pageNo){
					
				}
				that.listTzgg = that.listTzgg.concat(res.data.list)

				that.finished = false;
				that.refreshing = false
				if (res.data.list.length < that.pageSize) { 
					// console.log(111)
					this.finishedText = '加载完成'
					that.finished = true;
					that.refreshing = false;
					
				}else{
					that.refreshing =false; //上面loding
					that.finished = true; //下面loding
				}
				
			},
			onLoads(){
			},
			onRefresh() {
				this.pageNo++
				this.finished = true;
				this.loading = true;
				this.getList();
			},
			handelclick(item){
				this.$router.push({
					path:'/tzgg/content',
					query:{id:item.id}
				})
			}
			
		},
	};
	// export default {
	// 	name: 'TzggIndex',
	// 	data() {
	// 		return {
	// 			pageNo: 1, // 当前页码
	// 			pageSize: 10, // 每页显示的数量
	// 			totalCount: 0, // 总数量
	// 			pageList: [], // 分页列表数据
	// 			list: [],
				
	// 		};
	// 	},
	// 	created() {
	// 		this.getList()
	// 	},
	// 	methods: {
	// 		mounted() {
	// 			// 初始化时加载第一页数据
	// 			this.fetchData();
	// 		},
	// 		// 获取列表
	// 		async getList() {
	// 			const res = await getTzggData(this.pageNo, this.pageSize)
	// 			this.pageList = res.data.list
	// 			// console.log(res.data.list);
	// 			// console.log(res.data.list,99999);
	// 		},
			
	// 		fetchData() {
	// 			// 发送请求获取下一页数据
	// 			axios.get('/client/user/oaNotify/list', {
	// 				params: {
	// 					page: this.pageNo + 1, // 下一页页码
	// 					pageSize: this.pageSize, // 每页显示的数量
	// 				},
	// 			})
	// 			.then(response => {
	// 				if (response.status === 200) {
	// 					// 处理返回的数据
	// 					this.totalCount = response.data.totalCount; // 更新总数量
	// 					this.pageList = [...this.pageList, ...response.data.list]; // 拼接下一页数据到分页列表中
	// 					this.pageNo += 1; // 更新当前页码
	// 				} else {
	// 					console.log('HTTP请求失败，状态码：', response.status);
	// 					// 在这里你可能想要给用户显示一个错误消息
	// 				}
	// 			})
	// 			.catch(error => {
	// 				console.log(error);
	// 			});
	// 		},
	// 	},
	// };
	
</script>

<style>
	.van-nav-bar__arrow {
		color: #000000 !important;
		font-size: 20px;
	}
	.tzgg{
		width: 95%;
		height: auto;
		display: flex;
		flex-direction: column;
		margin: 20px auto;
	}
	.tzgg ul{
		width: 95%;
		height: auto;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
	}
	.tzgg ul li{
		width: 100%;
		height: 50px;
		line-height: 50px;
		border-bottom: solid 1px #EEEEEE;
		font-size: 14px;
		color: #333333;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.tzgg button{
		width: 100px;
		height: 30px;
		border: none;
		background-color: #F52C2E;
		margin: 20px auto;
		color: #FFFFFF;
		border-radius: 5px;
	}
</style>