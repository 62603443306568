import request from '@/utils/request'

// 获取我的数据
export const getUserData = () => {
	return request.get('/client/user/info', {
	
	})
}
// 修改头像
export const updatePhotoData = (photo) => {
	return request.post('/client/user/updatePhoto', {
	photo
	})
}

// 退出登录
export const quitLogin = () => {
	return request.get('/client/user/logout', {
		
	})
}