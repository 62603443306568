<template>
	<div>
		<div class="van-nav-tb">
			<van-nav-bar
				title="培训通知"
				left-text=""
				left-arrow
				@click-left="$router.go(-1)"
			/>
		</div>
		<div class="content">
			<h1>{{ detailData.title }}</h1>
			<p>{{ detailData.content }}</p>
		</div>
		<GbFooter></GbFooter>
	</div>
</template>

<script>
	import request from '@/utils/request'
	export default {
		name: 'ContentIndex',
		data() {
			return {
				id:'',
				detailData: '' // 用于存储从详情接口获取的数据
			};
		},
		created() {
			if(this.$route.query){
				this.id = this.$route.query.id;
				this.fetchDetailData(); // 在组件创建时发送请求获取详情数据
			}
		},
		methods: {
		    async fetchDetailData() {
		      try {
		        // 发送GET请求到详情接口，使用id作为查询参数
		        const response = await request.get(`/client/user/oaNotify/view?id=${this.id}`);
		        this.detailData = response.data; // 将获取的数据存储在detailData中
		      } catch (error) {
		        console.error('Error fetching detail data:', error);
		      }
		    }
		}
	};
</script>

<style>
	.van-nav-bar__arrow {
		color: #000000 !important;
		font-size: 20px;
	}
	.content{
		width: 95%;
		height: auto;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
	}
	.content h1{
		width: 95%;
		height: auto;
		margin: 20px auto;
		font-size: 18px;
		font-weight: bold;
		color: #333333;
		text-align: center;
	}
	.content p{
		width: 95%;
		height: auto;
		margin: 0 auto;
		font-size: 14px;
		font-weight: bold;
		color: #333333;
	}
</style>