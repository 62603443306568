<template>
	<div class="mine">
		<div class="van-nav-tb">
			<van-nav-bar
				title="我的"
				left-text=""
				left-arrow
				@click-left="$router.go(-1)"
			/>
		</div>
		<div class="avatar">
			<div class="avatar_tx">
				<!-- <img src="@/assets/toux.png" alt="" /> -->
				<van-uploader @oversize="onOversize" :before-read="beforeRead" :after-read="clzpAfterRead" 
				v-model="fileList" :deletable="false" :max-count="1" :preview-size="89" :preview-full-image="true">
				  <img class="avatar_tx_img" src="@/assets/toux.png" alt="" />
				</van-uploader>
			</div>
			<h1>{{ informAtion.name }}</h1>
		</div>
		<div class="cell_1">
			<van-cell-group inset>
				<div class="cell">
					<img src="@/assets/gh.png" alt="" style="width: 19px; height: 18px; margin-left: 5px;" />
					<div class="cell_1_1">工号</div>
					<div class="cell_1_2">{{ informAtion.no }}</div>
					<!-- <van-cell title="工号" value="">{{ informAtion.no }}</van-cell> -->
				</div>
				<div class="cell">
					<img src="@/assets/gw.png" alt="" style="width: 21px; height: 22px; margin-left: 5px;" />
					<div class="cell_1_1">职务</div>
					<div class="cell_1_2">{{ informAtion.duty }}</div>
					<!-- <van-cell class="cell_1_1" title="职务" value="">{{ informAtion.duty }}</van-cell> -->
				</div>
				<div class="cell">
					<img src="@/assets/sgdw1.png" style="width: 19px; height: 19px; margin-left: 5px;" alt="" />
					<div class="cell_1_1">所属单位</div>
					<div class="cell_1_2" v-if="informAtion.office">{{ informAtion.office.name }}</div>
					<!-- <van-cell class="cell_1_1" title="所属单位" value="" v-if="informAtion.office" >{{ informAtion.office.name }}</van-cell> -->
				</div>
			</van-cell-group>
		</div>
		<van-cell-group inset style="margin-top: 15px;">
			<router-link to="/xgmm">
				<div class="cell">
					<img src="@/assets/xgmm.png" alt="" style="width: 19px; height: 18px; margin-left: 5px;" />
					<van-cell title="修改密码" is-link url="#" />
				</div>
			</router-link>
		</van-cell-group>
		<button class="tcdl" @click="logout" >退出登录</button>
		<!-- Dialog弹窗 -->
		<div>
			<van-dialog v-model="show" title="是否保存头像" 
			:show-cancel-button="true" 
			confirmButtonText="确认" 
			cancelButtonText="取消" 
			@confirm="postForm()" 
			cancle="onCancle">
			</van-dialog>
		</div>
	</div>
</template>

<script>
	import { getUserData } from '@/api/user'
	import { quitLogin } from '@/api/user'
	import { getUploadData } from '@/api/xssq'
	import { updatePhotoData } from '@/api/user'
	import request from '@/utils/request'
	export default {
		name:'UserIndex',
		data() {
			return {
				informAtion: {},
				photo: '',
				fileList: [
				], //上传的文件列表
				remarks: '',
				file: [],
				img:"",
				show: false,
			}
		},
		created() {
			this.created(); // 在组件创建时发送请求获取详情数据
		},
		methods: {
			//校验上传图片大小
			onOversize(file) {
				console.log(file);
				this.$toast("文件大小不能超过5MB");
			},
			//校验图片的格式
			beforeRead(file) {
				if (!/(jpg|jpeg|png|JPG|PNG)/i.test(file.type)) {
					this.$toast("请上传正确格式的图片");
					return false;
				}
				return true;
			},
			//照片上传事件方法
			async  clzpAfterRead(file) {
				// 上传状态提示开启
				// file.status = "uploading";
				// file.message = "上传中...";
				// 创建一个空对象实例
				var formData = new FormData();
				 
				// 调用append()方法添加数据
				formData.append("file", file.file);
				const res = await getUploadData({file: file.file})
				if (res.code == "200") {
					console.log(res.url,111)
					// 上传状态提示关闭
					file.status = "done";
					this.$toast("上传成功！");
					this.photo = res.url
					this.show = true
				}
			},
			
			async created () {
				const res = await getUserData()
				this.informAtion = res.data
				console.log(this.informAtion,1212312)
				if (res.data.phone !== "") {
					let url = `http://yqgjk.ewdjy.cn/prod-api${res.data.photo}`
					this.fileList.push({url:url})
				} else{
					let url = require('@/assets/toux.png')
					this.fileList.push({url:url})
				}
			},
			// 提交
			async postForm () {
				const res = await updatePhotoData( this.photo )
				if(res.code==200){
					// 申请成功，显示成功消息或跳转到其他页面
					this.$toast('保存成功');
					// const url = this.$route.query.backUrl || '/';
					// this.$router.replace(url);
				}else{
					// 其他消息，根据msg信息进行相应处理
					this.$toast(res.msg);
				}
			},
			// 退出登录
			async logout () {
				const res = await quitLogin()
				console.log(res, 99999)
				if(res.code==200){
					// 更新成功，显示成功消息或跳转到其他页面
					this.$toast('退出成功');
					const url = this.$route.query.backUrl || '/login';
					this.$router.replace(url);
				}else{
					// 其他消息，根据msg信息进行相应处理
					this.$toast(res.msg);
				}
			},
		},
	};
</script>

<style>
	.van-nav-bar__arrow {
		color: #000000 !important;
		font-size: 20px;
	}
	.mine{
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		background-color: #EFEFEF;
	}
	.avatar{
		height: 248px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: url("@/assets/toububj.png") no-repeat;
		background-size: 100% 100%;
	}
	.avatar_tx{
		width: 90px;
		height: 90px;
		text-align: center;
	}
	.avatar_tx img{
		width: 89px;
		height: 89px;
	}
	.avatar_tx_img{
		position: relative;
		bottom: 0;
	}
	.van-image{
		border-radius: 50px;
		width: 80px;
		height: 80px;
	}
	.van-image__img{
		border-radius: 50px;
	}
	.avatar h1{
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 29px;
	}
	.cell_1{
		margin-top: 20px;
	}
	.cell_1_1{
		width: 22%;
		height: auto;
		color: #323233;
		font-size: 14px;
		margin-left: 10px;
	}
	.cell_1_2{
		width: 70%;
		height: auto;
		color: #969799;
		font-size: 14px;
		line-height: 20px;
	}
	.cell{
		width: 90%;
		height: 47px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		border-bottom: solid 1px #E5E5E5;
	}
	.tcdl{
		width: 100px;
		height: 30px;
		margin: 30px auto;
		border: none;
		background-color: #E5E5E5;
		color: #999999;
		font-size: 14PX;
		border-radius: 5px;
	}
</style>