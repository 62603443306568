import { render, staticRenderFns } from "./GbHeader.vue?vue&type=template&id=cbcc59d8&scoped=true"
import script from "./GbHeader.vue?vue&type=script&lang=js"
export * from "./GbHeader.vue?vue&type=script&lang=js"
import style0 from "./GbHeader.vue?vue&type=style&index=0&id=cbcc59d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbcc59d8",
  null
  
)

export default component.exports