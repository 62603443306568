//设置token
export function setToken(token) {
	return sessionStorage.setItem("token", token)
}
//获取token
export function getToken(token) {
	return sessionStorage.getItem("token", token)
}
//清除token
export function removeToken(token) {
	return sessionStorage.removeItem("token", token)
}