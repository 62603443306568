import request from '@/utils/request'

export const xgmmEdit = (username, password, confirmPassword, code, uuid) => {
  return request.post('/client/resetPwd', {
    username,
    password,
    confirmPassword,
    code,
    uuid
  });
};
export const fsyzmEdit = (username) => {
	return request.get(`/client/sendCaptcha?username=${username}`, {
		username
	})
}