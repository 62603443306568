import request from '@/utils/request'

/**
 * 参训率列表
 * @param {} params 
 * @returns 
 */
export const listCxl = (params) => request.get('/client/user/trainingClassStudent/hourTotalPassedList', { params })

/**
 * 计算单位参训率
 * @param {*} params 
 * @returns 
 */
export const computedCxl = (params) => request.post('/client/user/trainingClassStudent/findCxl', params, {
  headers: {
    'Content-Type': 'application/json'
  }
})